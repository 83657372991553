import Purchase from 'ConversionAPIs/Purchase';
import Axios from 'axios';
import axiosUrl from 'libs/axiosUrl';

import { CART_CLEAR_ITEMS } from 'shared/constants/cartConstant';
import {
  ORDER_CART_REQUEST,
  ORDER_CART_SUCCESS,
  ORDER_CART_FAIL,
} from 'shared/constants/orderConstants';

export const orderProducts = (orderCart) => async (dispatch) => {
  dispatch({
    type: ORDER_CART_REQUEST,
  });
  try {
    const data = await Axios.post(`${axiosUrl}/order-mails`, orderCart);
    alert('Proizvod je uspešno naručen! Proverite vaš email.');
    await Purchase(orderCart);
    sessionStorage.removeItem('cartItems');
    dispatch({ type: ORDER_CART_SUCCESS, payload: data });
    dispatch({ type: CART_CLEAR_ITEMS });
    const response = await Axios.get(`${axiosUrl}/counters`);
    let currentCounter = response.data[0].Counter;
    const newCounter = currentCounter + 1;
    const counterId = process.env.REACT_APP_COUNTER;
    await Axios.put(`${axiosUrl}/counters/${counterId}`, {
      Counter: newCounter,
    });

    try {
      await fetch(process.env.REACT_APP_GOOGLE_SHEET, {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain;charset=utf-8' },
        body: JSON.stringify(orderCart),
      });
    } catch (error) {
      console.error('Error:', error);
    }
  } catch (error) {
    alert('Došlo je do greške, pokušajte ponovo.');
    dispatch({ type: ORDER_CART_FAIL, payload: error.message });
  }
};
